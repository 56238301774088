@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";


body
{
    margin:0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Segoe UI Symbol';
}

.Resizer 
{
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    &:hover 
    {
        -webkit-transition: all 2s ease;
        transition: all 2s ease;
    }
    
    &.horizontal 
    {
        height: 11px;
        margin: -5px 0;
        border-top: 5px solid rgba(255, 255, 255, 0);
        border-bottom: 5px solid rgba(255, 255, 255, 0);
        cursor: row-resize;
        width: 100%;
        &:hover {
            border-top: 5px solid rgba(0, 0, 0, 0.5);
            border-bottom: 5px solid rgba(0, 0, 0, 0.5);
        }
    }
    
    &.vertical {
        width: 11px;
        margin: 0 -5px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;
        &:hover {
            border-left: 5px solid rgba(0, 0, 0, 0.5);
            border-right: 5px solid rgba(0, 0, 0, 0.5);
        }
    }
    
    &.disabled {
      cursor: not-allowed;
      &:hover {
        border-color: transparent;
      }
    }
}


.h2editor-page
{
    // max-height:100vh;
    // position: relative;
    // overflow-y: hidden;
    .story
    {
        height:100%;
        display:flex;
        flex-direction: row;
        .editbox
        {
            flex:1;
            display:flex;
            flex-direction: column;
            .talklist
            {
                flex:4;
                background-color: #f1f1f1;
            }
            .talkform
            {
                background-color: white;
                flex:1;
                min-height:200px;
                max-height:200px;
                max-width:400px;
                min-width:400px;
                margin-left:auto;
                margin-right:auto;
                // border-top:1px solid #eee;
                padding-top:10px;
                display:flex;
                flex-direction: row;
                
                .left
                {
                    flex:1;
                    max-width:90px;
                    min-width:90px;
                    padding:10px;
                    .rolecard
                    {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        overflow-wrap: break-word;
                    }
                    
                }
                .right
                {
                    flex:1;
                    padding:10px;
                    .textarea
                    {
                        textarea
                        {
                            min-height:120px;
                        }
                    }
                    .actions
                    {
                        margin-top:10px;
                    }
                }
                .narration
                {
                    background-color: rgb(9, 168, 248);
                    border-radius: 3px;
                    padding:2px;
                    color:white;
                }
            }
        }
        .previewbox
        {
            background-color:#f1f1f1;
            border-left:1px dashed #aaa;
            flex:1;
        }
        .printbox
        {
            background-color:#f1f1f1;
            border-left:1px dashed #aaa;
            flex:1;
        }
    }

    .toolbar
    {
        padding-bottom: 5px;
        height: 100%;
        background-color: #f1f1f1;
        gap: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .logo {
            padding: 10px 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .logo-image {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }
    
    .resource
    {
        height:100%;
        display: flex;
        flex-direction: row;

        .left-section {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        
        .bookbox
        {
            max-height:300px;
            min-height:100px;
            overflow-y: auto;
            flex:1;
            padding:10px;
            .editline
            {
                .bp3-icon
                {
                    margin-right:5px;
                    svg
                    {
                        fill:#aaa;
                    }
                }
                display:flex;
                margin-bottom:8px;
                .title 
                { 
                    > *
                    {
                        font-size:16px;
                    }
                }
        
                    
                
            }
        }
        .avatarlist
        {
            flex:2;
            border-top:1px solid #eee;
            padding:10px;
            // padding-bottom:10px;
            .theitem
            {
                display: inline-block;
            }

            > *
            {
                
                width:64px;
                height:64px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:not(:last-child)
                {
                    margin-right:10px;
                }
                margin-bottom:10px;
                
            }

            .avatar-upload
            {
                display: inline-block;
                width:64px;
                height:64px;
                cursor:pointer;
                border-radius: 5px;
                border:1px solid #ccc;
                svg
                {
                    fill:#ccc;
                    color:#ccc;
                }
            }

            .avatar-item
            {
                display: inline-block;
                img
                {
                    max-width: 100%;
                    border-radius: 5px;
                    border:1px solid #ccc;
                    cursor: pointer;
                    box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.15);
                }

                &.main
                {
                   img
                   {
                    border:2px solid black; 
                   } 
                }

                &.star
                {
                    position: relative;
                    &::after
                    {
                        content:"✡︎";
                        color:white;
                        text-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
                        font-size:30px;
                        position: absolute;
                        bottom:0px;
                        right:5px;
                    }
                    

                }
            }
        }

        // background-color: antiquewhite;
        // border-radius: 5px;
    }
    
    .side
    {
        min-height:100vh;
        // overflow-y: hidden;
        background-color: #fbfbfB;
        display: flex;
        flex-direction: column;
        .logo
        {
            flex:1;
            min-height:200px;
            max-height:200px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            .logo-image
            {
                max-width: 160px;
            }
        }
        .chapter-box
        {
            flex:5;
            border-top:1px solid #eee;
            padding-top:3px;
            padding-bottom:10px;
            .search
            {
                padding:5px;
            }

            .list
            {
                ul
                {
                    padding:0px;
                    margin:0px;
                }
                max-height: calc( 100vh - 250px );
                overflow-y: auto;
                .chapter-item
                {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding:10px;
                    cursor: pointer;

                    .chapter_edit
                    {
                        input
                        {
                            max-width:140px;
                            color:#333;
                        }

                        .bp3-editable-text-content
                        {
                            max-width:140px;
                            text-overflow: ellipsis;
                        }
                        
                        
                    }
                    
                    span.handle
                    {
                        visibility: hidden;
                    }
                    
                    &:hover
                    {
                        background-color: rgb(9, 168, 248)!important;
                        color:white;
                        span.handle
                        {
                            visibility: visible;
                        }
                        
                    }

                    &.active
                    {
                        background-color:  rgb(31, 182, 255);
                        color:white;
                    }
                }
            }
        }
        .chapter-intro
        {
            flex:1;
            min-height:200px;
            max-height:200px;
            border-top:1px solid #eee;
            padding-top:10px;
            padding-bottom:10px;
            .introbox
            {
                max-height:180px;
                overflow-y:auto;
                padding:10px;
                .notes 
                {
                    & *
                    {
                        font-size:14px!important;
                    }

                    > *:nth-child(2)
                    {
                        margin:0px!important;
                    }
                    textarea
                    {
                        width:100%;
                        min-height:120px;
                    }
                }
            }
        }
    }
}

.talklist-edt
{
    background-color: #f1f1f1;
    // padding:10px;
    height:calc( 100vh - 200px );
    overflow-y:auto;
    max-width:400px;
    margin-left:auto;
    margin-right:auto;
    
}

.talklist-ro
{
    background-color: #f1f1f1;
    height:100%;  
    max-width:400px;
    margin-left:auto;
    margin-right:auto;
    
    .thelist
    {
        // padding:10px;
        height:calc( 100vh - 200px );
        overflow-y:auto;
    }

    .touchpad
    {
        max-height: 200px;
        min-height: 200px;
        border-top: 1px solid #ddd;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.talklist-ro
{
    .guide
    {
        text-align: center;
        margin-top:10px;
        font-size:16px;
        color:#333;
    }
}

.snap-talk-list
{
    position: absolute;
    *
    {
        z-index:-99;
    }

    z-index:-99;
    
    background-color: #f2f2f2;
    padding-bottom: 200px;

    .talk-list-item-ro
    {
        .contentside
        {
            .text
            {
                box-shadow:0px 0px 0px !important;
                padding:8px;
                p
                {
                    margin:0px;
                }
                // border-radius: 3px;
                // &::after
                // {
                //     // content:""!important;
                // }


            }
        }

        &.notmain .text
        {
            // position: relative;
            border:8px solid white!important;
            border-radius: 3px!important;
            box-shadow:0px 0px 0px !important;
            padding:0px!important;
            &::after
            {
                // content:'◆';
                content:'✦'!important;
                position: absolute!important;
                left:-12px!important;
                top:-8px!important;
                color:white;
                // z-index:9999!important;
            }
        }

        &.main .text
        {
            border:8px solid rgb(31, 182, 255) !important;
            border-radius: 3px!important;
            box-shadow:0px 0px 0px !important;
            padding:0px!important;
            &::after
            {
                // content:'◆';
                content:'✦'!important;
                position: absolute!important;
                right:-12px!important;
                top:-8px!important;
                color:rgb(31, 182, 255)!important;
                // z-index:9999!important;
            }
        }
    }
}

span.blink
{
  animation: blinker 2s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.noselect 
{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  -webkit-tap-highlight-color:  rgba(255, 255, 255, .2);                                 
}



.talk-list-item-edt
{
    &:hover
    {
        background-color: bisque;
    }

    &.active
    {
        background-color: antiquewhite;
    }
}

.talk-list-item-edt,.talk-list-item-ro
{
    *{
        word-break: break-all;
    }

    img.imginbox
    {
        max-width: 100%;
        border-radius: 8px;
        padding:5px;
    }
    
    &.aside
    { 
        a
        {
            color:white;
            border-bottom:1px solid white;
            text-decoration: none
        }
    };

    &.role
    { 
        &.notmain
        {
            a
            {
                color:black;
                border-bottom:1px solid black;
                text-decoration: none
            }
        }
        
        &.main
        {
            a
            {
                color:white;
                border-bottom:1px solid white;
            }
        }
    };

    


    padding:10px;
    //line-height: 1.8;
    
    .bp3-editable-text-content
    {
        // height: fit-content;
        min-height: 18px!important;
    }
    &.aside
    {
        text-align:center;
        .aside-text
        {
            p{margin:0px;}
            background-color: #ccc;
            padding:5px;
            padding-left:8px;
            padding-right:8px;
            color:white;
            border-radius: 3px;
            width:fit-content;
            max-width:60%;
            text-align:left;
            margin-left:auto;
            margin-right:auto;
            .inplace-edit
            {
                textarea,input
                {
                    color:#333;
                    max-width:300px;
                }
            }
        }

    }

    &.role
    {
        display:flex;
        flex-direction: row;
        
        
        .avatarside
        {
            flex:1;
            max-width:50px;
        }
        .contentside
        {
            flex:4;
            display: flex;
            flex-direction: column;

            .name
            {
                color:#aaa;
                width:fit-content;
            }
            .text
            {
                padding:8px;
                border-radius: 3px;
                display:inline-block;
                margin-top:2px;
                width:fit-content;
                max-width:60%;
                overflow-wrap: break-word;
                position: relative;
                box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.15);
                p{margin:0px;}

                .inplace-edit
                {
                    textarea
                    {
                        width:100%;
                        color:#333;
                    }
                }
                
            }
        }

        &.notmain
        {
            
            .contentside
            {
                padding-left:8px;
                .text
                {
                    p{margin:0px;}
                    z-index:2;
                    background-color: white;
                    box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.15);
                    &::after
                    {
                        // content:'◆';
                        content:'✦';
                        position: absolute;
                        left:-4px;
                        top:1px;
                        color:white;
                        z-index:-1;
                    }
                }
            }
        }

        &.main
        {
            flex-direction: row-reverse;
            .contentside
            {
                align-items: flex-end;
                //text-align:center;
                // background-color: red;
                flex-direction: column;
                padding-right:8px;
                .name
                {
                    // margin-left:auto;
                }
                .text
                {
                    
                    //margin-left:auto;
                    p{margin:0px;}
                    z-index:2;
                    background-color: rgb(31, 182, 255);
                    color:white;
                    &::after
                    {
                        // content:'◆';
                        content:'✦';
                        position: absolute;
                        right:-4px;
                        top:1px;
                        color:rgb(31, 182, 255);
                        z-index:-1;
                    }
                }
            }
        }
    }
    margin-bottom:15px;
}

.avatar-square
{
    background-color: white;
    border-radius: 5px;
    img
    {
        max-width:100%;
        border-radius: 5px;
        border:1px solid rgba(200, 200, 200, 0.5);
    }

    &.shadow
    {
        img
        {
            box-shadow: 2px 2px 0px 0px rgba(0,0,0,0.15);
        }
        
    }
}

.shadow1
{
    img
    {
        box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.15);
    }
    
}

.react-contextmenu
{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu-item
{
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
    &:hover
    {
        color:white;
        background-color: rgb(9, 168, 248);
    }
}

.gray
{
    svg
    {
        fill:#ddd;
        color:#ddd;
    }
}

.s2row
{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.std-hr
{
    border-top: 1px solid #eee;
    margin-top:30px;
    height:30px; 
    width:calc( 100% + 40px );
    position: relative;
    left:-20px;
}

.top10
{
    margin-top:10px;
}

.s64
{
    max-width:64px;
    max-height:64px;

}

.s48
{
    max-width:48px;
    max-height:48px;

}

.s32
{
    max-width:32px;
    max-height:32px;

}

.avatar-overbox
{
    z-index:999;
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    
    .center-box
    {
        width:300px;
        min-height:200px;
        background-color: white;
        padding:20px;
        border-radius: 5px;
        border:1px solid #eee;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        &.cropper-box {
            width: 420px;
            padding: 20px;
            
            .cropper-container {
                width: 380px !important;
                height: 380px !important;
                margin: 0 auto;
                overflow: hidden;
                
                .cropper-wrap-box {
                    background-color: #f5f5f5;
                }
                
                .cropper-canvas {
                    top: 0 !important;
                    left: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                }
                
                .cropper-view-box {
                    border-radius: 5px;
                    outline: none;
                }
                
                .cropper-face {
                    background: none;
                    border: 2px solid #09a8f8;
                }
                
                .cropper-line, .cropper-point {
                    background-color: #09a8f8;
                    opacity: 0.75;
                }

                .cropper-modal {
                    background-color: rgba(0, 0, 0, 0.5);
                    opacity: 0.5;
                }

                img {
                    max-width: none !important;
                    max-height: none !important;
                    transform-origin: 0 0 !important;
                }

                .cropper-crop-box {
                    top: 0 !important;
                }
            }

            .buttons-row {
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
                gap: 10px;
            }
        }

        .file-row
        {
            margin-bottom: 20px;
            position: relative;
            
            label {
                display: block;
                margin-bottom: 8px;
            }
            
            > div {
                margin-bottom: 20px;
            }
        }
    }
}

hr.divider
{
    color:#eee;
    border:1px solid #eee;
    margin-top:20px;
    margin-bottom:20px;
}

.row
{
    display:flex;
    flex-direction: row;
    > *
    {
        flex:1;
    }
    .right
    {
        text-align:right;
    }
}

.toast
{
    position: fixed;
    margin:0 auto;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding:10px;
    border-radius: 5px;
    background-color:rgba($color: #666, $alpha: .9);
    color:white;
    transition-property: opacity  bottom ;
    transition-duration: 1s;
    opacity: 0;

    &.show
    {
        opacity: 1;
        bottom: 40px;
    }
}

::-webkit-scrollbar-button{ display: block; height:0px; border-radius: 3px; background-color: rgba($color: #666, $alpha: 0.2) }; 

::-webkit-scrollbar-thumb{ 
    background-color: rgba($color: #666, $alpha: .2);
    border-radius:10px;
} 

::-webkit-scrollbar-track,::-webkit-scrollbar-track-piece
{ 
    background-color: rgba($color: #666, $alpha: 0);border-radius:10px;
};

::-webkit-scrollbar
{ 
    width: 3px;
}

*:focus
{
    outline:none!important;
}

.bottom10
{
    margin-bottom:10px;
}